import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        'header',
    ];

    connect() {
        this.didScroll = false;
        this.lastScrollTop = 0;
        this.isSuspended = false;
        this.delta = 200;

        const me = this;

        // Scroll event handling
        $(window).on('scroll', function() {
            me.didScroll = true;
        });

        setInterval(function() {
            if (me.didScroll && !me.isSuspended) {
                me.#hasScrolled();
                me.didScroll = false;
            }
        }, 250);
    }

    hide() {
        $(this.headerTarget).removeClass('nav-down').addClass('nav-up');
        // access badge-target data attribute outside of the controller(no need to write controller for badge)
        $('[data-badge-target]').removeClass('badge-show').addClass('badge-hide');
    }

    show() {
        $(this.headerTarget).removeClass('nav-up').addClass('nav-down');
        $('[data-badge-target]').removeClass('badge-hide').addClass('badge-show');
    }

    suspend() {
        this.isSuspended = true;
    }

    resume() {
        this.lastScrollTop = $(window).scrollTop();
        this.isSuspended = false;
    }

    // private

    #hasScrolled() {
        let st = $(window).scrollTop();
        if (Math.abs(this.lastScrollTop - st) <= this.delta) {
            return;
        }
        let navbarHeight = $(this.headerTarget).outerHeight();
        if (st > this.lastScrollTop && st > navbarHeight) {
            this.hide();
        } else if (st + $(window).height() < $(document).height()) {
            this.show();
        }
        this.lastScrollTop = st;
    }
}
