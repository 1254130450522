import { ViewportObserver } from '../utils/ViewportObserver';

window.addEventListener(
    "load",
    (event) => {
        document.querySelectorAll(".viewport-observer").forEach((el) => {
            new ViewportObserver(el, (isIntersecting) => {
                if (isIntersecting) {
                    el.classList.add("is-in-viewport");
                }
            });
        });

        document.querySelectorAll(".countup-observer").forEach((el) => {
            new ViewportObserver(el, (isIntersecting) => {
                if (isIntersecting && !el.classList.contains("has-counted-up")) {
                    el.classList.add("has-counted-up");
                    el.querySelectorAll(".countup-item").forEach((item) => animateCountUp(item, el.dataset.lang));
                }
            });
        });

        document.querySelectorAll(".video-observer").forEach((el) => {
            new ViewportObserver(el, (isIntersecting) => {
                if (isIntersecting && !el.classList.contains("is-playing")) {
                    el.classList.add("is-playing");
                    el.play();
                }
            });
        });
    },
    false
);

// https://jshakespeare.com/simple-count-up-number-animation-javascript-react/
const animationDuration = 2000; // equals css animation duration
const frameDuration = 1000 / 60;
const totalFrames = Math.round(animationDuration / frameDuration);
const easeOutQuad = (t) => t * (2 - t);

const animateCountUp = (el, lang) => {
    let frame = 0;
    const countTo = parseFloat(el.dataset.countTo);
    const isDecimalCount = countTo < 10;

    const counter = setInterval(() => {
        frame++;
        const progress = easeOutQuad(frame / totalFrames);
        let currentCount = isDecimalCount
            ? Math.round(countTo * progress * 100) / 100
            : Math.round(countTo * progress);

        if (lang == "de") currentCount = currentCount.toLocaleString("de-DE");
        if (parseFloat(el.innerHTML) !== currentCount) el.innerHTML = currentCount;
        if (frame === totalFrames) clearInterval(counter);
    }, frameDuration);
};
