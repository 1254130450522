import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { delay: Number, block: Number };

  connect() {
    const lastShownKey = `eventModalLastShown-${this.blockValue}`;
    const lastShown = localStorage.getItem(lastShownKey);
    const startOfToday = new Date().setHours(0, 0, 0, 0);
   
    if (lastShown && parseInt(lastShown, 10) >= startOfToday) {
      return;
    }
    
    this.modalTarget = this.element.querySelector(`[data-event-modal-target="modal-${this.blockValue}"]`);
    if (this.modalTarget) {
      this.timeout = setTimeout(() => {
        this.openModal();        
      }, this.delayValue*1000 || 10000);
    }
  }

  openModal() {
    if (this.modalTarget) {
      let modalId = "#event-modal-"+this.blockValue;
      $(modalId).modal('show');

      const lastShownKey = `eventModalLastShown-${this.blockValue}`;
      localStorage.setItem(lastShownKey, new Date().getTime());
    }
  }

  disconnect() {
    clearTimeout(this.timeout);
  }
}
