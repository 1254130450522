import { Controller } from '@hotwired/stimulus';
import { debounce } from 'lodash';

/**
 * @type {'push' | 'replace'} Whether to use history replace or history push while filtering.
 */
const historyMode = 'replace';

/**
 * @type {Number} Number of milliseconds to delay actual search on user input.
 */
const inputDebounce = 200;

/**
 * @param {String} term
 * @return {String}
 */
function escapeSearchTerm(term) {
    return term
        .replace('ß', 'ss')
        .replace('ä', 'ae')
        .replace('ü', 'ue')
        .replace('ö', 'oe');
}

/**
 * Filterable downloads component.
 */
export default class extends Controller {
    static targets = [
        'categoryField',
        'downloadItem',
        'resultCounter',
        'searchField'
    ];

    static outlets = [
        'header'
    ];

    static values = {
        scrollIntoView: {
            type: Boolean,
            default: false
        }
    };

    connect() {
        // If category hash or query param are set, scroll to component and filter downloads.
        let categoryId = null;

        const params = new URLSearchParams(window.location.search);
        if (params.has(this.context.identifier + '-category')) {
            categoryId = params.get(this.context.identifier + '-category');
        } else {
            // Handle deprecated hash urls.
            categoryId = window.location.hash.substring(1);
            if (categoryId && this.#categoryExists(categoryId)) {
                window.location.hash = '';
            } else {
                categoryId = null;
            }
        }

        if (categoryId) {
            this.#doFilterCategories(categoryId);
        }

        if (this.scrollIntoViewValue) {
            // Scroll to top of component.
            const scrollTop = $(this.element).offset().top - 100;
            // Suspend header auto hide on scroll.
            this.headerOutlet.suspend()
            $('html, body').stop(true).animate({ scrollTop }, {
                duration: 500,
                complete: function() {
                    this.headerOutlet.resume();
                }.bind(this)
            });
        }
    }

    /**
     * Create debounced version of #doSearch().
     */
    search = debounce(() => this.#doSearch(), inputDebounce);

    filterCategories({ params: { categoryId } }) {
        this.#doFilterCategories(categoryId);
    }

    // private

    #doSearch() {
        if (!this.hasDownloadItemTarget) {
            return;
        }
        $(this.downloadItemTargets).removeClass('nohit');

        let searchTerm = this.hasSearchFieldTarget && $(this.searchFieldTarget).val().trim();
        if (searchTerm) {
            let searchWords = escapeSearchTerm(searchTerm.toLowerCase()).split(' ');

            // Add "nohit" class to all items not matching user search term.
            const nonMatchedItems = this.downloadItemTargets.filter((el) => {
                let content = escapeSearchTerm(el.innerHTML.toLowerCase());
                return !searchWords.some((word) => content.search(word) !== -1);
            });
            $(nonMatchedItems).addClass('nohit');
        }

        this.#updateResultsCounter();
    }

    #doFilterCategories(categoryId, rewriteUrl = true) {
        if (!this.hasDownloadItemTarget) {
            return;
        }
        const url = new URL(document.location.href);
        const $downloadItems = $(this.downloadItemTargets);

        // Ensure category exists.
        if (!this.#categoryExists(categoryId)) {
            categoryId = 'all';
        }

        if (categoryId === 'all') {
            $downloadItems.show();
            url.searchParams.delete(this.context.identifier + '-category');
        } else {
            categoryId = parseInt(categoryId, 10);
            $downloadItems.each((_, el) => {
                const $el = $(el);
                const categoryIds = $el.data('category') || [];
                $el.toggle(categoryIds.includes(categoryId));
            });
            url.searchParams.set(this.context.identifier + '-category', categoryId);
        }
        if (this.hasCategoryFieldTarget) {
            $(this.categoryFieldTargets).filter(`#downloadcat-${categoryId}`).prop('checked', 'checked');
        }

        // Rewrite url.
        if (rewriteUrl) {
            let uri = url.toString();
            // Remove empty trailing hash, if any.
            if (uri.endsWith('#')) {
                uri = uri.substring(0, uri.length - 1);
            }
            history[historyMode === 'push' ? 'pushState' : 'replaceState']({}, '', uri);
        }

        this.#updateResultsCounter();
    }

    #updateResultsCounter() {
        if (this.hasResultCounterTarget) {
            const numResults = this.hasDownloadItemTarget && $(this.downloadItemTargets).filter(':visible').length;
            $(this.resultCounterTarget).text(numResults || 0);
        }
    }

    /**
     * Check whether given category id can be selected or not.
     *
     * @param {number | string} categoryId
     * @return {boolean} Whether given category id can be selected or not.
     */
    #categoryExists(categoryId) {
        return (
            categoryId === 'all' ||
            (this.hasCategoryFieldTarget && !!$(this.categoryFieldTargets).filter(`#downloadcat-${categoryId}`).length)
        );
    }
}
