import { Controller } from '@hotwired/stimulus';

/**
 * @type {'push' | 'replace'} Whether to use history replace or history push while navigating product sliders.
 */
const historyMode = 'replace';

/**
 * @type {'string'} Css class used for accordion open state.
 */
const classOpen = 'tab-accordion__open';

/**
 * @type {'string'} Css class used for active tab titles and active tab content.
 */
const classTabActive = 'tab-accordion__tab-active';

/**
 * Tab accordion component.
 */
export default class extends Controller {
    static targets = [
        'tabTitle',
        'tabContent',
        'mobileSelect'
    ];

    static values = {
        initialTab: {
            type: Number,
            default: -1
        }
    };

    /**
     * @type {string[]}
     */
    #tabSlugs;

    connect() {
        // Collect tabs.
        this.#tabSlugs = this.tabContentTargets.map((tabContent) => tabContent.id);

        // On load switch to tab by url hash, if set.
        const activeTabSlug = window.location.hash.substring(1);
        if (activeTabSlug && this.#getTabIndex(activeTabSlug) !== -1) {
            this.#doOpenTab(activeTabSlug);
        } else if (
            this.initialTabValue >= 0 &&
            this.initialTabValue < this.#tabSlugs.length
        ) {
            this.#doOpenTab(this.#tabSlugs[this.initialTabValue], false, false);
        }
    }

    /**
     * @param {ActionEvent} event
     */
    openTab(event) {
        let { currentTarget, params: { slug } } = event;
        // If called by mobile select action, get slug from selected option.
        if (this.hasMobileSelectTarget && currentTarget === this.mobileSelectTarget) {
            slug = this.mobileSelectTarget.value;
        }
        this.#doOpenTab(slug);
        event.preventDefault();
    }

    close() {
        const $element = $(this.element);
        $element.fadeOut(250, () => {
            $(this.tabTitleTargets).removeClass(classTabActive);
            $(this.tabContentTargets)
                .stop(true)
                .removeClass(classTabActive);
            $element
                .removeClass(classOpen)
                .fadeIn(250);
        });
    }

    // private

    /**
     * Open tab for given slug.
     *
     * @param {string} slug
     * @param {boolean} animateActivation
     * @param {boolean} rewriteUrl
     */
    #doOpenTab(slug, animateActivation = true, rewriteUrl = true) {
        // Check if slug is valid.
        const tabIndex = this.#getTabIndex(slug);
        if (tabIndex === -1) {
            return;
        }

        // Check if we are in initial or open mode.
        const $element = $(this.element);
        if (!$element.hasClass(classOpen)) {
            if (animateActivation) {
                $element.fadeOut(250, () => {
                    $element
                        .addClass(classOpen)
                        .fadeIn(250);
                    this.#doOpenTab(slug);
                });
                return;
            }

            $element.addClass(classOpen);
        }

        // Change active tab title.
        const $tabTitleTargets = $(this.tabTitleTargets);
        $tabTitleTargets.removeClass(classTabActive);
        $tabTitleTargets.eq(tabIndex).addClass(classTabActive);

        // Update mobile select.
        if (this.hasMobileSelectTarget) {
            this.mobileSelectTarget.value = slug;
        }

        // Update url hash.
        const activeTabSlug = window.location.hash.substring(1);
        if (rewriteUrl && activeTabSlug !== slug) {
            const url = new URL(document.location.href);
            url.hash = `#${slug}`;
            // Update browser history.
            history[historyMode === 'push' ? 'pushState' : 'replaceState']({}, '', url);
        }

        const $tabContentTargets = $(this.tabContentTargets);

        // Stop any pending animation.
        $tabContentTargets.stop(true);

        // Animate to active tab content.
        const $currentTabContentTargets = $tabContentTargets.filter('.' + classTabActive);
        if (
            animateActivation && $currentTabContentTargets.length &&
            slug !== activeTabSlug
        ) {
            $currentTabContentTargets.fadeOut(250, () => {
                this.#activateTab(tabIndex, slug);
            });
            return;
        }

        this.#activateTab(tabIndex, animateActivation);
    }

    /**
     * Activate tab.
     *
     * @param {int} tabIndex
     * @param {boolean} animateActivation
     */
    #activateTab(tabIndex, animateActivation = true) {
        const $tabContentTargets = $(this.tabContentTargets);
        $tabContentTargets.removeClass(classTabActive);
        const $activeTab = $tabContentTargets.eq(tabIndex);
        $activeTab.addClass(classTabActive);

        if (animateActivation) {
            $activeTab
                .hide(0)
                .fadeIn(250);
        }

        // Trigger tab-activate event
        $(this.element).trigger('tab-activate', [$activeTab[0]]);
    }


    /**
     * Get tab index of given slug.
     *
     * @param {string} slug
     * @returns {int} The index of given tab slug or -1, if it dows not exist.
     */
    #getTabIndex(slug) {
        return this.#tabSlugs.indexOf(slug);
    }
}
