import { Controller } from '@hotwired/stimulus';

const baseApiUrl = 'https://my.wieland.com';

const templates = {
    /**
     * Important! Changes to the menu DOM structure must also be adopted to
     * templates\themes\wieland\element\header_menu.html.twig.
     */
    menu: {
        template: `
            <li class="menu-item menu-item--expandable">
                <span class="menu-item-title"> myWieland </span>

                <div class="sub-menu">
                    <div class="sub-menu-title">myWieland</div>
                    <i class="menu-close fal fa-times"></i>

                    <div class="row sub-menu-elements"></div>
                </div>
            </li>
        `,
        selectors: {
            title: '.menu-item-title, .sub-menu-title',
            categoryWrapper: '.sub-menu-elements'
        }
    },
    category: {
        template: `
            <div class="col-xl-3">
                <ul class="sub-sub-menu">
                    <li class="menu-item">
                        <span class="menu-item-title"></span>
                        <ul class="sub-sub-sub-menu"></ul>
                    </li>
                </ul>
            </div>
        `,
        selectors: {
            title: '.menu-item-title',
            menuItemWrapper: '.sub-sub-sub-menu'
        }
    },
    menuItem: {
        template: `
            <li class="menu-item">
                <a class="menu-item-title"></a>
            </li>
        `,
        selectors: {
            title: '.menu-item-title'
        }
    },
    popover: {
        // The popover template may use placeholders for options passed in ctrl.optionValue.
        template: `
            <div id="myWielandProfilePopover">
                <a href="/{{ language }}/mywieland/manage-user/detail">{{ strings.profile }}</a>
                <hr>
                <a href="/{{ language }}/mywieland" class="btn btn-primary">{{ strings.login }}</a>
                <a href="#" style="display: none;" class="btn btn-secondary">{{ strings.logout }}</a>
            </div>
        `,
        selectors: {
            container: '#myWielandProfilePopover',
            loginButton: '.btn-primary',
            logoutButton: '.btn-secondary',
        }
    },
};

const menuCategories = [
    'purchasing',
    'service',
    'account',
    'general'
];

/**
 * myWieland component for dynamic creation of myWieland header menu and user
 * oauth login via header menu popover.
 *
 * This controller listens for the following events on document body, which may
 * be triggert by myWieland app:
 * - `mywieland:menu:updated`: Rerender myWieland menu. Should be sent, whenever
 *  myWieland menu information in local storage has changed.
 * - `mywieland:menu:close`: Close header menu. Can be used in conjunction with
 *  `mywieland:menu:itemclick` callbacks to close header menu after myWieland
 *  menu item was clicked.
 *
 * This controller fires the following custom events.
 * - `mywieland:menu:itemclick`: Is triggert after every click to a myWieland
 *  menu item. Prevent default on the event to stop browser from further
 *  processing the original anchor link. Additionally the event object holds
 *  the original menu item configuration in `detail` property.
 */
export default class extends Controller {
    static targets = [
        'loginToggle',
        'menu',
    ];

    static values = {
        options: Object
    };

    static outlets = [
        'navigation'
    ];

    connect() {
        this.#setupPopover();
        this.#setupMenu();
    }

    /**
     * Check whether current user is logged in to myWieland.
     *
     * @returns {boolean} `true`, if current user is logged in to myWieland and access is not expired else `false`.
     */
    isUserLoggedIn() {
        let token = this.#getPKCEAccessToken();
        if (token !== null && !this.#isTokenExpired()) {
            return true;
        }

        return false;
    }

    // private

    #setupPopover() {
        const $loginToggle = $(this.loginToggleTarget);
        const config = templates.popover;

        // Replace placeholders in popup template.
        const options = this.optionsValue;
        const popoverContent = config.template.replace(/\{\{\s*([^\s}]+)\s*\}\}/g, (fullMatch, match) => {
            const replacement = this.#getHierarchical(options, match);
            return replacement !== undefined ? replacement : fullMatch;
        });

        // Allow style attribute in popover content
        $.fn.tooltip.Constructor.Default.allowList['*'].push('style');

        // Setup popup.
        $loginToggle.popover({
            container: 'body',
            content: popoverContent,
            html: true,
            placement: 'bottom'
        });

        $loginToggle.on('shown.bs.popover', () => {
            const $loginButton = $(config.selectors.container).find(config.selectors.loginButton);
            const $logoutButton = $(config.selectors.container).find(config.selectors.logoutButton);
            const isUserLoggedIn = this.isUserLoggedIn();

            $loginButton.toggle(!isUserLoggedIn);
            $logoutButton.toggle(isUserLoggedIn);

            $(config.selectors.container).css('margin-top', 0);
            $logoutButton.off('click').on('click', async (e) => {
                e.preventDefault();
                await this.#logout();
                window.location.href = `/${this.optionsValue.language}/mywieland/logout`;
            })
        });
    }

    /**
     * Setup menu and myWieland app event listeners.
     */
    #setupMenu() {
        this.#renderMenu();

        // Listen to events from myWieland app.
        $('body').on({
            'mywieland:menu:updated': () => this.#renderMenu(),
            'mywieland:menu:close': () => this.navigationOutlet.closeMenus(),
        });
    }

    /**
     * Render menu.
     */
    #renderMenu() {
        const $menu = $(this.menuTarget);
        const myWielandMenuCache = localStorage.getItem('myWielandMenuCache');

        // If user is not logged in or menu config cannot be retrieved from local storage remove "myWieland" menu item.
        if (!myWielandMenuCache || !this.isUserLoggedIn()) {
            $menu.empty();
            return;
        }

        // Check if menu already exists and could be reused.
        const config = templates.menu;
        let $categoryWrapper = $menu.find(config.selectors.categoryWrapper);
        if (!$categoryWrapper.length) {
            $menu.html(templates.menu.template);
            $categoryWrapper = $menu.find(config.selectors.categoryWrapper);
        }

        // Clear menu content.
        $categoryWrapper.empty();

        const menuItems = JSON.parse(myWielandMenuCache);
        menuCategories.forEach((category) => {
            const config = templates.category;
            const $categoryEntry = $(config.template);
            $categoryEntry.find(config.selectors.title).text(this.optionsValue.strings[category]);

            const $menuItemWrapper = $categoryEntry.find(config.selectors.menuItemWrapper);
            menuItems.forEach((menuItem) => {
                if (menuItem.category === category) {
                    const config = templates.menuItem;
                    const $menuEntry = $(config.template);
                    const $menuTitle = $menuEntry.find(config.selectors.title);
                    $menuTitle.text(menuItem.name);
                    $menuTitle[0].href = `/${this.optionsValue.language}/mywieland/${menuItem.url}`
                    $menuTitle.on('click', (event) => this.#menuItemClick(event, menuItem));

                    $menuItemWrapper.append($menuEntry);
                }
            });

            $categoryWrapper.append($categoryEntry);
        });
    }

    /**
     * Forward original event as custom mywieland event.
     *
     * @param {jQuery.Event} event The original event object.
     * @param {Object} menuItem The menu item config.
     * @returns {false|void} False if any handler prevented default else void.
     */
    #menuItemClick(event, menuItem) {
        const allowDefault = event.target.dispatchEvent(new CustomEvent('mywieland:menu:itemclick', {
            bubbles: true,
            cancelable: true,
            detail: menuItem
        }));

        if (!allowDefault) {
            event.preventDefault();
            return false;
        }
    }

    /**
     * Get object value by key in dot notation.
     *
     * @param {Object} o The object to fetch the value from.
     * @param {string} key The key in dot notation.
     * @returns {any} The value of given object at specified key or undefined, if not found.
     */
    #getHierarchical(o, key) {
        let dict = o,
            keys = key.split('.'),
            i = 0,
            l = keys.length;

        while (dict && i < l) {
            dict = dict[keys[i++]];
        }

        return dict;
    }

    /**
     * Logout current user from oauth session.
     */
    async #logout() {
        const token = this.#getPKCEAccessToken();
        try {
            await fetch(`${baseApiUrl}/authorizationserver/oauth/revoke`, {
                method: 'POST',
                mode: 'cors',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: `token=${token}`
            });
        } catch(error) {
            console.warn('Logout error: ', error.message);
        } finally {
            localStorage.removeItem('auth_info');
            localStorage.removeItem('userInfo');
        }
    }

    /**
     * Retrieve stored oauth access token.
     *
     * @returns {string|null} Stored oauth access token or `null` if no such information exists.
     */
    #getPKCEAccessToken() {
        const authInfo = this.#getAuthInfo();
        return authInfo ? authInfo.token : null;
    }

    /**
     * Retrieve expiration timestamp of stored oauth access token.
     *
     * @returns {int|null} Unix timestamp when oauth token will expire or `null` if no such information exists.
     */
    #getTokenExpirationTimestamp() {
        const authInfo = this.#getAuthInfo();
        return authInfo ? authInfo.expiration_time : null;
    }

    /**
     * Get parsed auth info from local storage, if any.
     *
     * @returns {Object|null} The parsed auth info object or `null` if no such object exists in local storage.
     */
    #getAuthInfo() {
        const authInfo = localStorage.getItem('auth_info');
        if (authInfo !== null) {
            return JSON.parse(authInfo);
        }

        return null;
    }

    /**
     * Check whether stored oauth access token is expired.
     *
     * @returns {boolean} `true`, if no oauth access token is stored or it is expired else `false`.
     */
    #isTokenExpired() {
        const currentTimestamp = Math.floor((new Date()).getTime() / 1000);
        const tokenExpirationTime = this.#getTokenExpirationTimestamp();

        if (tokenExpirationTime && tokenExpirationTime > currentTimestamp) {
            return false;
        }

        // Remove expired token info.
        localStorage.removeItem('auth_info');
        return true;
    }
}
