import { Controller } from '@hotwired/stimulus';
import Chart from 'chart.js/auto';
import { throttle } from 'lodash';
import { ViewportObserver } from '../utils/ViewportObserver';

/**
 * Metal chart component.
 */
export default class extends Controller {
    static values = {
        ajaxUrl: String
    };

    /**
     * @type {ViewportObserver}
     */
    #viewportObserver;

    connect() {
        // Register viewport observer to show element on viewport intersection.
        this.#viewportObserver = new ViewportObserver(this.element, (isIntersecting) => {
            if (!isIntersecting) {
                return;
            }
            // Unregister viewport observer.
            this.#viewportObserver.destroy();

            // Fetch metal price data and init chart.
            $.ajax({
                method: 'GET',
                url: this.ajaxUrlValue,
                success: ({ content: { chart: config } }) => {
                    this.#initChart(config)
                }
            });
        });
    }

    disconnect() {
        // Cleanup.
        this.#viewportObserver.destroy();
    }

    // private

    #initChart(config) {
        const getLinePointRadius = () => (
            config.data.length > 150 || window.innerWidth <= 680 ? 0 : 2
        );
        const { labels, data } = config;

        // Calculate and match data and labels to grid.
        const numTicks = 20;
        const tickSpan = Math.ceil(data.length / numTicks);
        let numData = Math.floor(data.length / tickSpan) * tickSpan + 1;
        if (numData > data.length) {
            numData -= tickSpan;
        }
        while (data.length > numData) {
            data.shift();
            labels.shift();
        }

        // Init chart.
        Chart.defaults.font.family = 'museo-sans, sans-serif';
        const chart = new Chart($(this.element).find('canvas.metal-chart')[0], {
            type: 'line',
            data: {
                labels: labels,
                datasets: [
                    {
                        data: data,
                        tension: 0,
                        fill: false,
                        borderWidth: 1,
                        borderColor: '#e0251b',
                        backgroundColor: 'white',
                        pointRadius: getLinePointRadius()
                    }
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: 0
                },
                plugins: {
                    legend: {
                        display: false
                    },
                    title: false,
                    hover: {
                        animationDuration: 0
                    },
                },
                scales: {
                    x: {
                        grid: {
                            drawTicks: false
                        },
                        ticks: {
                            display: false,
                            callback: (tickValue, index, _ticks) => {
                                return index % tickSpan === 0 || index === numData - 1 ? '' : null;
                            }
                        }
                    }
                }
            }
        });

        // Watch resize and adopt point radius.
        $(window).on('resize', throttle(function() {
            chart.data.datasets[0].pointRadius = getLinePointRadius();
        }, 250));

        // Hide spinner and reveal chart.
        $(this.element).removeClass('loading');
    }
}
