/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// Import global libs.
import 'bootstrap/dist/js/bootstrap';
import 'jquery/dist/jquery.min';
// import 'masonry-layout/masonry';
import 'slick-carousel/slick/slick';

// Start the Stimulus application.
import './bootstrap';

// Import modules.
import './modules/enctypedMail';
import './modules/lazyLoad';
import './modules/sliders';
import './modules/intersections';

// Make some libraries globally available
global.jQuery = global.$ = jQuery;

import '@chenfengyuan/datepicker/dist/datepicker.min.js?raw';
import '@chenfengyuan/datepicker/i18n/datepicker.de-DE.js?raw';
import '@chenfengyuan/datepicker/i18n/datepicker.en-GB.js?raw';

