import { Controller } from '@hotwired/stimulus';

export default class VirtualAssistantController extends Controller {
    static targets = ['messageLatest', 'vabuttons', 'vabuttonback'];

    #vaStack = [];

    connect() {
        this.#renderVA(vaitems);

        // Show the badge advisor
        $('[data-badge-advisor-target]').show();
    }

    handleClick(event) {
        const button = event.currentTarget;
        if (button.dataset.message) {
            this.messageLatestTarget.textContent = button.dataset.message;
        }

        if (button.dataset.children) {
            this.#renderVA(JSON.parse(button.dataset.children));
            return;
        }

        if (button.dataset.href) {
            if (button.dataset.href.startsWith("http")) {
                window.open(button.dataset.href, '_blank').focus();
            } else {
                window.location = `${button.dataset.href}`;
            }
        }
    }

    handleBack(event) {
        this.messageLatestTarget.textContent = document.documentElement.lang === "de" ?
            'Benötigen Sie Hilfe bei der Suche nach Informationen?' :
            'Do you need help finding information?';

        this.#vaStack.pop();
        const lastItems = this.#vaStack.pop();
        if (lastItems) {
            this.#renderVA(lastItems);
        } else {
            this.#renderVA(vaitems);
        }
    }

    #private

    #renderVA(items) {
        this.#vaStack.push(items);
        let newItems = document.createDocumentFragment();
        const currentHost = window.location.protocol + "//" + window.location.host;
        items.forEach((vaitem) => {
            let newButton = document.createElement('div');
            newButton.className = 'button-wrap vabutton';
            newButton.textContent = vaitem.name;
            newButton.dataset.action = 'click->virtual-assistant#handleClick';
            this.#assignDataAttributes(newButton, vaitem, currentHost);
            newItems.appendChild(newButton);
        });

        if (vaitems !== items) {
            let backButton = document.createElement('div');
            backButton.className = 'button-wrap vabuttonback';
            backButton.textContent = document.documentElement.lang === "de" ? "← Zurück" : "← Back";
            backButton.dataset.action = 'click->virtual-assistant#handleBack';
            newItems.appendChild(backButton);
        }

        this.vabuttonsTarget.innerHTML = '';
        this.vabuttonsTarget.appendChild(newItems);
    }

    #assignDataAttributes(button, vaitem, currentHost) {
        if (vaitem.children) {
            button.dataset.children = JSON.stringify(vaitem.children);
        }
        if (vaitem.link_internal) {
            button.dataset.href = vaitem.link_internal;
        }
        if (vaitem.link_external) {
            if (vaitem.link_external.startsWith('http://#')) {
                button.dataset.href = vaitem.link_internal + vaitem.link_external.substring(7);
            } else {
                button.dataset.href = vaitem.link_external.replace('https://master-7rqtwti-b2ifhuk3u36cq.eu-4.platformsh.site', currentHost);
            }
        }
        if (vaitem.message) {
            button.dataset.message = vaitem.message;
        }
    }
}
