import { Controller } from '@hotwired/stimulus';

/**
 * Events component.
 */
export default class extends Controller {
    static targets = [
        'navigation',
        'mobileToggle'
    ];

    connect() {
        const me = this;
        const $navigation = $(this.navigationTarget);
        const $mobileToggle = $(this.mobileToggleTarget);

        // Watch click on menu item titles.
        $navigation.on('click', '.menu-item--expandable > .menu-item-title', function(event) {
            event.stopPropagation();

            const $menuItem = $(this).parent();
            const $menu = $menuItem.parent();
            const isMainMenu = $menu.hasClass('menu');
            const isActive = $menuItem.hasClass('show');

            // Check if main menu item or sub menu item :after toggle icon was clicked.
            const toggleWidth = parseInt(window.getComputedStyle(this, '::after').width, 10);
            if (isMainMenu || event.offsetX > this.offsetWidth - toggleWidth) {
                // Stop location change by link (if any).
                event.preventDefault();

                // On desktop view open main menu without any animation.
                const animate = !isMainMenu || window.innerWidth <= 1200;

                // Close all other menus not containing the clicked menu item.
                me.#toggle(
                    me.#getExpandableMenuItems().filter(
                        (_i, el) => $(el).hasClass('show') &&
                        !$(el).has(this).length
                    ),
                    false,
                    animate
                );

                // Show/hide clicked sub menu.
                me.#toggle(
                    $menuItem,
                    !isActive,
                    animate
                );
            }

            // Focus search input on sub menu open.
            if (!isActive && $menu.hasClass('search')) {
                window.setTimeout(function() {
                    $menu.find('.input-search')[0].focus();
                }, 500);
            }
        });

        // Watch click on submenu close toggles.
        $navigation.on('click', '.menu-close', () => {
            // Close all open menus.
            this.closeMenus();
        });

        // Watch click on submenu close toggles.
        $mobileToggle.on('click', (e) => {
            e.preventDefault();
            $mobileToggle.toggleClass('is-active');
            $navigation.toggleClass('show', $mobileToggle.hasClass('is-active'));
        });
    }

    /**
     * Close all open menus.
     */
    closeMenus() {
        debugger;
        const $navigation = $(this.navigationTarget);
        const $mobileToggle = $(this.mobileToggleTarget);

        this.#toggle(this.#getExpandableMenuItems().filter('.show', false));

        $mobileToggle.removeClass('is-active');
        $navigation.removeClass('show');
    }

    // private

    #getExpandableMenuItems() {
        return $(this.navigationTarget).find('.menu .menu-item--expandable');
    }

    #toggle($el, state, animate = false) {
        if (!$el.length) {
            return;
        }

        // Stop any pending animation.
        const $subMenu = $el.children('.sub-menu, ul').stop(true);
        const subMenuCallback = function() {
            // Reset css display property left over by slide animation to not conflict with css styling.
            $(this).removeAttr('style');
        };

        if (animate) {
            if ($el.hasClass('show')) {
                // Explicitly set sub menu to display block to prevent hiding by class removal.
                $subMenu.css('display', 'block');
            }
            $subMenu[state ? 'slideDown' : 'slideUp']('fast', subMenuCallback);
        } else {
            $subMenu.each(subMenuCallback);
        }

        // Toggle class at last to not influence slide animation.
        $el.toggleClass('show', state);
    }
}
