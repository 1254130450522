$(document).ready(function () {
    const defaultArrow = {
        prevArrow: "<span class='slick-prev pull-left'><i class='fal fa-chevron-left' aria-hidden='true'></i></span>",
        nextArrow: "<span class='slick-next pull-right'><i class='fal fa-chevron-right' aria-hidden='true'></i></span>",
    };

    const defaultResponsive = [
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
            },
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                dots: false,
            },
        },
    ];

    function initializeSlider(selector, config) {
        $(selector).slick(config);
    }

    function initializeSliders() {
        // Initialize slider-teaser
        const sliderTeaser = $(".slider-teaser");
        sliderTeaser.each(function () {
            const elemSelf = $(this);
            const idSubSlider = elemSelf.attr("data-sub-slider");

            let configImageSlider = {
                infinite: true,
                arrows: true,
                draggable: true,
                dots: true,
                rows: 0,
                autoplay: true,
                autoplaySpeed: 5000,
                ...defaultArrow,
            };

            let configSubSlider = {
                infinite: true,
                arrows: false,
                draggable: true,
                dots: false,
                rows: 0,
                adaptiveHeight: true,
            };

            if (idSubSlider !== undefined) {
                const elemSubSlider = $("#" + idSubSlider);
                configSubSlider.asNavFor = elemSelf;
                configImageSlider.asNavFor = elemSubSlider;
                elemSubSlider.slick(configSubSlider);
            }

            elemSelf.slick(configImageSlider);
        });

        // Initialize slider-content from content_slider.html.twig
        const $sliderContent = $(".slider-content");
        $sliderContent.each(function () {
            const elemSelf = $(this);
            const slidesToShow = elemSelf.attr("data-slides-to-show");
            elemSelf.slick({
                infinite: true,
                slidesToShow: slidesToShow,
                arrows: true,
                draggable: true,
                dots: false,
                ...defaultArrow,
                responsive: defaultResponsive,
            });
        });

        initializeSlider("#carousel-locations", {
            infinite: true,
            slidesToShow: 2,
            arrows: true,
            draggable: false,
            dots: false,
            ...defaultArrow,
            responsive: defaultResponsive,
        });

        initializeSlider("#carousel-capabilities", {
            infinite: true,
            slidesToShow: 2,
            arrows: true,
            draggable: false,
            dots: false,
            ...defaultArrow,
            responsive: defaultResponsive,
        });

        initializeSlider("#slider-home", {
            infinite: true,
            arrows: false,
            draggable: true,
            dots: true,
        });

        initializeSlider("#carousel-quality", {
            infinite: true,
            slidesToShow: 2,
            arrows: true,
            draggable: false,
            dots: false,
            ...defaultArrow,
            responsive: defaultResponsive,
        });

        initializeSlider("#carousel-quality-noimg", {
            infinite: true,
            slidesToShow: 2,
            arrows: true,
            draggable: false,
            dots: false,
            ...defaultArrow,
        });

        initializeSlider(".carousel-mobile", {
            infinite: true,
            slidesToShow: 1,
            draggable: false,
            dots: true,
            mobileFirst: true,
            ...defaultArrow,
            responsive: [{breakpoint: 576, settings: "unslick"}],
        });

        // Available on page https://www.wieland.com/en/slaskie
        initializeSlider("#carousel-company-sticky-nav", {
            infinite: false,
            speed: 300,
            slidesToShow: 1,
            centerMode: false,
            variableWidth: true,
            ...defaultArrow,
            responsive: [{breakpoint: 993, settings: "unslick"}],
        });

        // Available on page https://www.wieland.com/en/slaskie
        $("#carousel-company-sticky-nav").on("setPosition", function (event, slick) {
            const {$slider, $slides, $nextArrow, currentSlide, listWidth} = slick;

            // prepare further needed variables
            let slidesWidth = 0;
            let currentLeft = 0;
            $slides.each(function (index) {
                const width = $(this).width();
                slidesWidth += width;
                if (index < currentSlide) currentLeft += width;
            });

            // check for left edge
            if (currentSlide <= 0) $slider.addClass("is-left-edge");
            else $slider.removeClass("is-left-edge");

            // check if remaining items fit in remaining space
            if (slidesWidth - currentLeft <= listWidth) {
                $slider.addClass("is-right-edge");
                $nextArrow.addClass("slick-disabled");
            } else {
                $slider.removeClass("is-right-edge");
                $nextArrow.removeClass("slick-disabled");
            }

            // if slider is at no edge, add class accordingly
            if ($slider.hasClass("is-left-edge") || $slider.hasClass("is-right-edge"))
                $slider.removeClass("is-no-edge");
            else $slider.addClass("is-no-edge");

            // if slider isn't neccessary remove all
            if ($slider.hasClass("is-left-edge") && $slider.hasClass("is-right-edge"))
                $slider.removeClass("is-left-edge is-right-edge is-no-edge");
        });
    }

    initializeSliders();
});
