import { Controller } from '@hotwired/stimulus';

/**
 * Feed component.
 */
export default class extends Controller {
    static targets = [
        'filterButton',
        'filterList',
        'newsItem'
    ];

    connect() {
        // If filter hash or query param are set, filter news items.
        let filterType = null;

        const params = new URLSearchParams(window.location.search);
        if (params.has(`${this.context.identifier}-filter`)) {
            filterType = params.get(this.context.identifier + '-filter');
        } else {
            // Handle deprecated hash urls.
            filterType = window.location.hash.substring(1);
            if (filterType && this.#filterExists(filterType)) {
                window.location.hash = '';
            } else {
                filterType = null;
            }
        }

        if (filterType) {
            this.#doFilter(filterType);
        }
    }

    /**
     * Toggle filter list.
     */
    toggleFilterList() {
        $(this.filterButtonTarget).addClass('active');
        $(this.filterListTarget).slideToggle();
    }

    /**
     * Toggle filters.
     */
    applyFilter({ params: { type } }) {
        this.#doFilter(type);
    }

    /**
     * Toggle share dropdown.
     */
    toggleShareDropdown({ currentTarget }) {
        const $currentTarget = $(currentTarget);
        const $shareDropdown = $currentTarget.next('.share-dropdown');
        // Close all share dropdowns on same feed.
        $currentTarget
            .closest('.component-feed')
            .find('.share-dropdown').not($shareDropdown)
            .slideUp('fast');

        // Toggle clicked share dropdown.
        $shareDropdown.slideToggle('fast');
    }

    /**
     * Copy url to clipboard.
     */
    copyToClipboard({ params: { url } }) {
        const el = document.createElement('textarea');
        el.value = url;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }

    // private

    /**
     * Check whether given filter type can be selected or not.
     *
     * @param {string} type
     */
    #doFilter(type) {
        // No news to filter.
        if (!this.hasNewsItemTarget) {
            return;
        }

        const $filterList = $(this.filterListTarget);
        $filterList.find('li').removeClass('active');
        const $filterItem = this.#getFilterItem(type);
        $filterItem.addClass('active');
        const $newsItemTargets = $(this.newsItemTargets);
        const url = new URL(document.location.href);

        // Ensure filter exists.
        if (!this.#filterExists(type)) {
            type = 'all';
        }

        if (type === 'all') {
            $newsItemTargets.show('fast');
            $(this.filterButtonTarget).removeClass('active');
            url.searchParams.delete(`${this.context.identifier}-filter`);
        } else {
            $newsItemTargets.not(`[data-type="${type}"]`).hide('fast');
            $newsItemTargets.filter(`[data-type="${type}"]`).show('fast');
            url.searchParams.set(`${this.context.identifier}-filter`, type);
        }

        // Close filter list.
        $filterList.slideUp();

        // Rewrite url.
        let uri = url.toString();
        // Remove empty trailing hash, if any.
        if (uri.endsWith('#')) {
            uri = uri.substring(0, uri.length - 1);
        }
        history.replaceState({}, '', uri);
    }

    /**
     * Check whether given filter type can be selected or not.
     *
     * @param {string} type
     * @return {boolean} Whether given filter type can be selected or not.
     */
    #filterExists(type) {
        return !!this.#getFilterItem(type).length;
    }

    /**
     * Get filter item for given type.
     *
     * @param {string} type
     * @return {jQuery} The filter item for given type.
     */
    #getFilterItem(type) {
        return this.hasFilterListTarget
            ? $(this.filterListTarget).find(`li[data-${this.context.identifier}-type-param=${type}]`)
            : $()
        ;
    }
}
