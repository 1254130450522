import { Controller } from '@hotwired/stimulus';

/**
 * Events component.
 */
export default class extends Controller {
    static targets = [
        'teaser',
    ];

    connect() {
        if (this.hasTeaserTarget) {
            $(this.teaserTargets).each((_, teaser) => {
                const $teaser = $(teaser);
                $teaser.html($teaser.html().replace(/(\|)/g, '<span style="color: red;">|</span>'));
            });
        }
    }
}
