const defaultOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 0.7,
};

class ViewportObserver {
    static #viewportObserver;
    static #viewportObservers = [];

    /**
     * @type {Element}
     */
    #el;
    #callback;

    /**
     *
     * @param {Element} el
     * @param {Function} callback
     * @param {Object} options
     */
    constructor(el, callback, options = defaultOptions) {
        this.#el = el;
        this.#callback = callback;

        if (!ViewportObserver.#viewportObserver) {
            ViewportObserver.#viewportObserver = new IntersectionObserver(
                ViewportObserver.#handleViewportIntersect,
                options
            );
        }

        ViewportObserver.#viewportObserver.observe(this.#el);
        ViewportObserver.#viewportObservers.push(this);
    }

    /**
     * @returns {Element}
     */
    get el() {
        return this.#el;
    }

    onIntersect(isIntersecting) {
        this.#callback(isIntersecting);
    }

    destroy() {
        ViewportObserver.#viewportObserver.unobserve(this.#el);
        ViewportObserver.#viewportObservers.splice(ViewportObserver.#viewportObservers.indexOf(this), 1);
    }

    static #handleViewportIntersect(entries) {
        entries.forEach((entry) => {
            const { isIntersecting, target } = entry;
            const { classList } = target;

            if (isIntersecting) {
                classList.add("is-in-viewport");
            }

            // Call all registered viewport observer callbacks for matching entries.
            ViewportObserver.#viewportObservers.forEach((observer) => {
                if (observer.el === target) {
                    observer.onIntersect(isIntersecting);
                }
            });
        });
    }
}

export { ViewportObserver };
