$(function() {
    'use strict';

    /**
     * Erlaubt es das href Attribut mit AES zu verschlüsseln. Bei einem Klick wird der verschlüsselte Wert
     * aus data-encrypted-href entschlüsselt und in den href gesetzt.
     * Geeignet um E-Mail-Adressen auf der Webseite gegen automatische Crawler zu verstecken.
     */
    $(document).on('click', 'a[data-encrypted-mail]', (event) => {
        const target = event.target;
        const encrypted = target.getAttribute('data-encrypted-mail');
        // Reverse string.
        const base64 = encrypted.split('').reverse().join('');
        //console.log(base64);
        const email = atob(base64);

        target.setAttribute('href', 'mailto:' + email);
        target.innerHTML = email;
    });
});
