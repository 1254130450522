import { ActionEvent, Controller } from '@hotwired/stimulus';

/**
 * @type {'push' | 'replace'} Whether to use history replace or history push while navigating product sliders.
 */
const historyMode = 'replace';

/**
 * Products slider component.
 */
export default class extends Controller {
    static targets = [
        'groupView',
        'groupField',
        'groupHeadline',

        'sliderContainer',
        'slideButton',
        'slider',
        'tableContent',

        'contact'
    ];

    static outlets = [
        'header'
    ];

    static values = {
        contentType: String,
        scrollIntoView: {
            type: Boolean,
            default: false
        }
    };

    /**
     * @type {boolean}
     */
    #isCompanyPage;

    /**
     * @type {string | undefined}
     */
    #currentGroup;

    connect() {
        this.#isCompanyPage = this.contentTypeValue === 'company';

        // Initialize slider.
        const $sliderTarget = $(this.sliderTarget);
        $sliderTarget.slick({
            infinite: true,
            arrows: false,
            draggable: false,
            swipe: false,
            fade: true,
            adaptiveHeight: true,
        });

        // If product (and metal) hash or query param are set, scroll to products component and open targeted slide.
        const $slideButtons = $(this.slideButtonTargets);
        const params = new URLSearchParams(window.location.search);
        let group = params.get(`${this.context.identifier}-group`);
        let type = null;
        let variant = null;
        if (params.has(`${this.context.identifier}-type`)) {
            type = params.get(`${this.context.identifier}-type`);
            variant = params.get(`${this.context.identifier}-variant`);
        } else {
            // Handle deprecated hash urls.
            [type, variant] = window.location.hash.substring(1).split(',');
            if (type) {
                const $currentSlideButton = $slideButtons.filter(`[data-type="${type}"]`);
                if ($currentSlideButton.length) {
                    window.location.hash = '';
                    // Try to infer group id by queried product type.
                    if (this.#isCompanyPage) {
                        group = $currentSlideButton.data('group');
                    }
                } else {
                    type = null;
                }
            }
        }

        if (group || (!this.#isCompanyPage && type)) {
            const $slideButton = $slideButtons.filter(`[data-type="${type}"]`);

            // Go to initial slide and scroll to products component.
            const slideIndex = $slideButton.data('slide');
            window.setTimeout(() => {
                this.#goTo(group, slideIndex, variant, this.scrollIntoViewValue);
            }, 100);

            return;
        }

        // Initially show group overview if on company page or go to first slide and first material table, if any.
        if (this.#isCompanyPage) {
            this.resetGroup();
        } else {
            this.#goTo(undefined, undefined, undefined, false, false);
        }
    }

    selectCompany({ currentTarget }) {
        const company = $(currentTarget).val();
        // TODO Check if history API would be a better choice than setting document.location.
        document.location = `?company=${company}#products`;
    }

    selectGroup({ currentTarget }) {
        // Check whether input or surrounding wrapper div called the action and determine input.
        if (!$(this.groupFieldTargets).filter(currentTarget).length) {
            currentTarget = $(currentTarget).find(this.groupFieldTargets);
        }
        const group = $(currentTarget).val();
        this.#goTo(group);
    }

    /**
     * @param {ActionEvent | undefined} event
     */
    resetGroup(event) {
        // Check if called by event controller.
        let scrollUp = false;
        if (event && ('preventDefault' in event)) {
            event.preventDefault();
            scrollUp = true;
        }

        this.#currentGroup = undefined;

        const $element = $(this.element);
        $(this.groupViewTarget).show();
        $(this.groupFieldTargets).prop('checked', false);
        $element.find('#product-types-row').hide();
        $element.find('#product-types-content').hide();

        // Scroll to top of product slider.
        if (scrollUp) {
            this.#scrollIntoView(this.sliderContainerTarget);
        }

        this.#updateUrl();
    }

    /**
     * @param {ActionEvent}
     */
    selectType({ currentTarget }) {
        this.#goTo(undefined, $(currentTarget).data('slide'));
    }

    /**
     * @param {ActionEvent} event
     * @returns {boolean}
     */
    selectVariant(event) {
        event.preventDefault();
        const { params: { variant } } = event;
        this.#goTo(undefined, undefined, variant, false);
        return false;
    }

    // private

    /**
     * @param {string | undefined} group
     * @param {int | undefined} slideIndex
     * @param {string | undefined} variant
     * @param {boolean} scrollUp
     * @param {boolean} rewriteUrl
     * @return {Promise<void>}
     */
    #goTo(group, slideIndex, variant, scrollUp = true, rewriteUrl = true) {
        return new Promise((resolve, _reject) => {
            if (!this.hasSliderTarget) {
                return resolve();
            }

            const $slideButtons = $(this.slideButtonTargets);

            // Handle group changes.
            if (this.#isCompanyPage) {
                if (!group) {
                    group = this.#currentGroup;
                }
                const $element = $(this.element);
                $(this.groupViewTarget).hide();
                $element.find('#product-types-row').show();
                $element.find('#product-types-content').show();

                const $groupField = $(this.groupFieldTargets).filter(`#product-group-${group}`);
                $groupField.prop('checked', 'checked');
                const groupLabel = $groupField.next().text();

                $(this.groupHeadlineTarget).text(groupLabel);

                $slideButtons.not(`[data-group="${group}"]`).hide();
                $slideButtons.filter(`[data-group="${group}"]`).show();

                $element.find('.product-portfolio__highlight-button').attr('href', $groupField.data('group-url'));
                const btnText = $('html').attr('lang') === 'de' ? 'Alle ' + groupLabel : 'All ' + groupLabel;
                $element.find('.product-portfolio__highlight-button-text').text(btnText);

                this.#currentGroup = group;
            }

            const $slider = $(this.sliderTarget);
            if (!slideIndex && slideIndex !== 0) {
                slideIndex = $slider.slick('slickCurrentSlide');
            }
            // If slide is not visible get index of first visible slide button.
            if (!$slideButtons.filter(`[data-slide="${slideIndex}"]`).is(':visible')) {
                slideIndex = $slideButtons.filter(':visible').eq(0).data('slide');
            }
            const $currentSlide = $slider.find(`.slick-slide[data-slick-index="${slideIndex}"]`);
            if (!variant) {
                variant = $currentSlide.find('.product-table-buttons > li:first-child > a').data(`${this.context.identifier}-variant-param`);
            }

            // Scroll to top of product slider.
            if (scrollUp) {
                this.#scrollIntoView(this.sliderContainerTarget).then(resolve);
            }

            // Set active product slide button.
            const $currentSlideButton = $slideButtons.filter(`[data-slide="${slideIndex}"]`);
            $slideButtons.find('.product-wrapper').removeClass('active');
            $currentSlideButton.find('.product-wrapper').addClass('active');

            // Update table to show the first material, if available.
            const $tableButtons = $currentSlide.find('.product-table-buttons a');
            const $currentTableButton = $tableButtons.filter(`[data-${this.context.identifier}-variant-param="${variant}"]`);
            const $tableContent = $currentSlide.find(this.tableContentTargets);
            const $currentTableContent = $tableContent.filter(`[data-type="${variant}"]`);

            $tableButtons.not($currentTableButton).removeClass('active');
            $currentTableButton.addClass('active');

            $tableContent.not($currentTableContent).hide();
            $currentTableContent.show();

            // Filter contacts for selected product type on material pages.
            if (this.contentTypeValue === 'materials_type' && this.hasContactTarget) {
                const productGroupId = $currentTableButton.data('productgroup');
                $(this.contactTargets).each(function() {
                    const $el = $(this);
                    $el.parent().toggle($.inArray(productGroupId, $el.data('productgroups')) > -1);
                });
            }

            // Slick to new slide.
            $slider.slick('slickGoTo', slideIndex);

            // Force slick slide content resize.
            $slider[0].slick.refresh();

            // Rewrite url to product id and table id.
            if (rewriteUrl) {
                this.#updateUrl(group, $currentSlideButton.data('type'), variant);
            }

            if (!scrollUp) {
                resolve();
            }
        });
    }

    /**
     * @param {string | undefined} group
     * @param {int | undefined} type
     * @param {string | undefined} variant
     */
    #updateUrl(group = undefined, type = undefined, variant = undefined) {
        // Ensure consistency.
        if (this.#isCompanyPage && !group) {
            type = undefined;
            variant = undefined;
        }
        if (!type) {
            variant = undefined;
        }

        // Set or remove query param.
        const url = new URL(document.location.href);
        const identifier = this.context.identifier;
        const params = { group, type, variant };
        for (let key in params) {
            if (Object.hasOwnProperty.call(params, key)) {
                const value = params[key];
                key = `${identifier}-${key}`;
                if (value) {
                    url.searchParams.set(key, value);
                } else {
                    url.searchParams.delete(key);
                }
            }
        }

        let uri = url.toString();
        // Remove empty trailing hash, if any.
        if (uri.endsWith('#')) {
            uri = uri.substring(0, uri.length - 1);
        }

        // Update browser history.
        history[historyMode === 'push' ? 'pushState' : 'replaceState']({}, '', uri);
    }

    /**
     * Scroll given element into view.
     *
     * @param {string | jQuery} selector
     * @return {Promise<void>}
     */
    #scrollIntoView(selector) {
        return new Promise((resolve, _reject) => {
            this.headerOutlet.hide();
            // Suspend header auto hide on scroll.
            this.headerOutlet.suspend();

            let scrollTop = $(selector).offset().top; // - 100;

            // Respect additional sticky navigation bar on company pages.
            if (this.#isCompanyPage) {
                scrollTop -= $('#company-sticky-nav').outerHeight();
            }

            $('html, body').stop(true).animate({ scrollTop }, {
                duration: 500,
                complete: () => {
                    // Resume header auto hide on scroll.
                    this.headerOutlet.resume();

                    resolve();
                }
            });
        });
    }
}
