import { Controller } from '@hotwired/stimulus';
import { debounce } from 'lodash';
import TomSelect from 'tom-select';

/**
 * @type {'push' | 'replace'} Whether to use history replace or history push while filtering.
 */
const historyMode = 'replace';

/**
 * @type {Number} Number of milliseconds to delay actual search on user input.
 */
const inputDebounce = 200;

/**
 * Jobs component.
 */
export default class extends Controller {
    static targets = [
        'numResults',
        'searchField',
        'categoriesField',
        'placesField',
        'item'
    ];

    static values = {
        entries: Array,
        categories: Array,
        places: Array,
        searchFilters: Object,
        searchResult: Object
    };

    /**
     * @type {{ search: string, categories: string[], places: string[] }}
     */
    #searchFilters = {
        search: '',
        categories: [],
        places: []
    };

    /**
     * @type {{ jobVacancyIds: int[], categories: Record<string, string>, places: Record<string, string> }}
     */
    #searchResult = {
        jobVacancyIds: [],
        categories: {},
        places: {}
    };

    /**
     * @type {TomSelect}
     */
    #tsCategoriesField;

    /**
     * @type {TomSelect}
     */
    #tsPlacesField;


    connect() {
        this.#searchFilters = $.extend({}, this.searchFiltersValue);
        this.#searchResult = $.extend({}, this.searchResultValue);

        this.#initSearchForm();
    }

    /**
     * Debounced search filter input callback.
     */
    search = debounce(
        () => {
            this.#searchFilters.search = $(this.searchFieldTarget).val();
            this.#search();
        },
        inputDebounce
    );

    // private

    /**
     * Init search form components.
     */
    #initSearchForm() {
        const tsCategoriesField = this.#tsCategoriesField = new TomSelect(this.categoriesFieldTarget, {
            plugins: {
                remove_button: {
                    // Hide non translated tooltip.
                    title: ''
                }
            },
            searchConjunction: 'or',
            render: {
                option: function(data, escape) {
                    return `
    <div>
        <span>${escape(data.text)}</span>
        <span class="component-jobs__filter__select__num-results">${escape(data.numResults)}</span>
    </div>
                    `;
                }
            }
        })
        tsCategoriesField.on(
            'item_add',
            /**
             * @param {string} categoryIdentifier
             */
            (categoryIdentifier) => {
                this.#searchFilters.categories.push(categoryIdentifier);
                this.#search();

                // Clear input value.
                tsCategoriesField.setTextboxValue(undefined);
                tsCategoriesField.refreshOptions();
            }
        );
        tsCategoriesField.on(
            'item_remove',
            /**
             * @param {string} categoryIdentifier
             */
            (categoryIdentifier) => {
                this.#searchFilters.categories.splice(this.#searchFilters.categories.indexOf(categoryIdentifier), 1);
                this.#search();
            }
        );

        const tsPlacesField = this.#tsPlacesField = new TomSelect(this.placesFieldTarget, {
            plugins: {
                remove_button:{
                    title: ''
                }
            },
            optgroupValueField: 'label',
            searchConjunction: 'or',
            lockOptgroupOrder: true,
            render: {
                option: function(data, escape) {
                    return `
    <div>
        <span>${escape(data.text)}</span>
        <span class="component-jobs__filter__select__num-results">${escape(data.numResults)}</span>
    </div>
                    `;
                }
            }
        });
        tsPlacesField.on(
            'item_add',
            /**
             * @param {string} placeIdentifier
             */
            (placeIdentifier) => {
                this.#searchFilters.places.push(placeIdentifier);
                this.#search();

                // Clear input value.
                tsPlacesField.setTextboxValue(undefined);
                tsPlacesField.refreshOptions();
            }
        );
        tsPlacesField.on(
            'item_remove',
            /**
             * @param {string} placeIdentifier
             */
            (placeIdentifier) => {
                this.#searchFilters.places.splice(this.#searchFilters.places.indexOf(placeIdentifier), 1);
                this.#search();
            }
        );
    }

    // #doSearch() {
    //     this.#searchFilters.search = $(this.searchFieldTarget).val();
    //     this.#search();
    // }

    /**
     * IMPORTANT: All changes to this method must also be applied to src/Controller/JobsController.php:search()
     */
    #search() {
        const searchFilters = this.#searchFilters;
        /** @type {{ id: int, name: string, url: string, isNew: boolean, companyNames: string[], categories: Record<string, string>, places: Record<string, string> }[]} */
        let resultEntries = Array.prototype.slice.call(this.entriesValue);

        // Filter text search.
        if (searchFilters.search.length) {
            /** @type {string[]} */
            const searchFragments = searchFilters.search
                .split(' ')
                .map((searchFragment) => searchFragment.trim().toLowerCase())
                .filter((searchFragment) => !!searchFragment);
            resultEntries = resultEntries.filter((jobVacancyEntry) => (
                searchFragments.some(
                    (searchFragment) => Object.values(jobVacancyEntry).some((value) => {
                        if (!value) {
                            return false;
                        }
                        /** @type {string[]} */
                        const values = Array.isArray(value) ? value : typeof value === 'object' ? Object.values(value) : [value];
                        return values.some((value) => `${value}`.toLowerCase().includes(searchFragment));
                    })
                )
            ));
        }

        // Collect result counts.
        // Hint: Categories filter and places filter depend on each other and are each filtered by the other filter.
        const jobVacancyIds = [];
        const categories = {};
        const places = {};
        resultEntries.forEach((jobVacancyEntry) => {
            let matches = 0;

            if (
                !searchFilters.categories.length ||
                Object.keys(jobVacancyEntry.categories).some((categoryIdentifier) => searchFilters.categories.includes(categoryIdentifier))
            ) {
                Object.keys(jobVacancyEntry.places).forEach((identifier) => {
                    places[identifier] = (places[identifier] || 0) + 1;
                });
                matches++;
            }

            if (
                !searchFilters.places.length ||
                Object.keys(jobVacancyEntry.places).some((placeIdentifier) => searchFilters.places.includes(placeIdentifier))
            ) {
                Object.keys(jobVacancyEntry.categories).forEach((identifier) => {
                    categories[identifier] = (categories[identifier] || 0) + 1;
                });
                matches++;
            }

            // Job vacancy results are only shown if all filters are matching.
            if (matches === 2) {
                jobVacancyIds.push(jobVacancyEntry.id);
            }
        });

        this.#searchResult = { jobVacancyIds, categories, places };

        this.#updateUrl();
        this.#updateSearchForm();
        this.#updateItems();
    }

    #updateUrl() {
        const searchFilters = this.#searchFilters;
        const url = new URL(document.location.href);
        const searchParams = url.searchParams;
        if (!searchFilters.search.length) {
            searchParams.delete('search');
        } else {
            searchParams.set('search', searchFilters.search);
        }
        if (!searchFilters.categories.length) {
            searchParams.delete('categories');
        } else {
            searchParams.set('categories', searchFilters.categories.join(','));
        }
        if (!searchFilters.places.length) {
            searchParams.delete('places');
        } else {
            searchParams.set('places', searchFilters.places.join(','));
        }
        let uri = url.toString();
        // Remove empty trailing hash, if any.
        if (uri.endsWith('#')) {
            uri = uri.substring(0, uri.length - 1);
        }
        history[historyMode === 'push' ? 'pushState' : 'replaceState']({}, '', uri);
    }

    #updateSearchForm() {
        const searchResult = this.#searchResult;

        // Update num results display.
        $(this.numResultsTarget).text(searchResult.jobVacancyIds.length);

        // Update categories select.
        /** @type {import('tom-select/dist/types/types').TomOption[]} */
        let options = [];
        this.categoriesValue.forEach((categoryEntry) => {
            const identifier = categoryEntry.identifier;
            const numResults = searchResult.categories[identifier] || 0;
            if (numResults > 0) {
                options.push({
                    value: identifier,
                    text: categoryEntry.name,
                    numResults
                });
            }
        });
        const tsCategoriesField = this.#tsCategoriesField;
        tsCategoriesField.clearOptions();
        tsCategoriesField.addOptions(options);

        // Update places select.
        /** @type string[] */
        const optGroups = [];
        options = [];
        this.placesValue.forEach(
            /**
             * @param {{ identifier: string, country: string, name: string }} placeEntry
             */
            (placeEntry) => {
                const identifier = placeEntry.identifier;
                const country = placeEntry.country;
                const numResults = searchResult.places[identifier] || 0;
                if (numResults > 0) {
                    options.push({
                        value: identifier,
                        text: placeEntry.name,
                        optgroup: country,
                        numResults
                    });
                    if (!optGroups.includes(country)) {
                        optGroups.push(country);
                    }
                }
            }
        );
        const tsPlacesField = this.#tsPlacesField;
        tsPlacesField.clearOptions();
        tsPlacesField.clearOptionGroups();
        optGroups.forEach((optGroup) => {
            tsPlacesField.addOptionGroup(optGroup, { label: optGroup });
        });
        tsPlacesField.addOptions(options);
    }

    #updateItems() {
        const { jobVacancyIds } = this.#searchResult;
        let isEven = true;
        this.itemTargets.forEach((item) => {
            const $item = $(item);
            const isVisible = jobVacancyIds.includes($item.data('jobVacancyId'));

            $item.toggleClass('component-jobs__job-vacancy--hidden', !isVisible);

            if (isVisible) {
                isEven = !isEven;
                $item.toggleClass('component-jobs__job-vacancy--even', isEven);
            }
        });
    }
}
