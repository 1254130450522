import { Controller } from '@hotwired/stimulus';
import Chart from 'chart.js/auto';
import { throttle } from 'lodash';

/**
 * Metal prices component.
 */
export default class extends Controller {
    static targets = [
        'detailWrapper'
    ];

    static values = {
        ajaxUrl: String,
        datepickerLanguage: String,
        datepickerEndDate: String,
    };

    /**
     * @var {Chart|null}
     */
    #chart = null;

    connect() {
        const $element = $(this.element);

        // If loaded into tabs, always show list mode when tab is activated and destroy chart on leave.
        $element.closest('.component-tab-accordion').on('tab-activate', () => {
            this.unloadDetails();
        });

        $(window).on('resize', throttle(() => {
            if (this.#chart) {
                const dataset = this.#chart.data.datasets[0];
                dataset.pointRadius = this.#getLinePointRadius(dataset.data);
                this.#chart.update();
            }
        }, 250));
    }

    /**
     * @param {ActionEvent} event
     */
    loadDetails(event) {
        // Clear detail wrapper.
        $(this.detailWrapperTarget).empty();

        const { params: { refKey } } = event;
        const url = `${this.ajaxUrlValue}?refKey=${refKey}`;
        this.#getDetails(url);
    }

    unloadDetails() {
        $(this.element).attr('data-state', 'list');
        this.#destroyChart();
    }

    showDatepicker() {
        $(this.element).find('.date-picker-wrapper')
            .attr('data-state', 'show')
            .find('input')
                .removeAttr('disabled');
    }

    hideDatepicker() {
        $(this.element).find('.date-picker-wrapper')
            .attr('data-state', 'hide')
            .find('input')
                .attr('disabled', 'disabled');
    }

    submitForm() {
        const $form = $(this.element).find('.metal-price-detail-form');
        const url = `${$form.attr('action')}?${$form.serialize()}`;
        this.#getDetails(url);
    }

    // private

    /**
     * @param {URL|string} url
     */
    #getDetails(url) {
        const $element = $(this.element);
        const $detailWrapper = $(this.detailWrapperTarget);

        $element
            .attr('data-state', 'details')
            .addClass('loading');

        $.ajax({
            method: 'GET',
            url
        }).done((response) => {
            this.#destroyChart();

            // Add response content to detail wrapper.
            $detailWrapper.html(response.content.template);

            this.#initDatePicker();
            this.#buildChart(response.content.chart);

            $element.removeClass('loading');
        });
    }

    #initDatePicker() {
        const $element = $(this.element);
        const $pickerFrom = $element.find('.datepicker-from');
        const $pickerTo = $element.find('.datepicker-to');
        const $triggerFrom = $pickerFrom.next();
        const $triggerTo = $pickerTo.next();

        const datepickerConfig = {
            language: this.datepickerLanguageValue,
            endDate: this.datepickerEndDateValue,
        };
        $pickerFrom.datepicker($.extend({}, datepickerConfig, {
            autoHide: true,
            trigger: $triggerFrom
        }));
        $pickerTo.datepicker($.extend({}, datepickerConfig, {
            autoHide: true,
            trigger: $triggerTo
        }));

        $pickerFrom
            // Remove possibly existing listener(s).
            .off('pick.datepicker')
            // Add listener.
            .on('pick.datepicker', () => {
                $pickerTo.datepicker('setStartDate', $pickerFrom.datepicker('getDate', true));
                $pickerTo.datepicker('show');
            });
        $pickerTo
            // Remove possibly existing listener(s).
            .off('pick.datepicker')
            // Add listener.
            .on('pick.datepicker', () => {
                $pickerFrom.datepicker('setEndDate', $pickerTo.datepicker('getDate', true));
            });
    }

    #buildChart(chartData) {
        Chart.defaults.font.family = 'museo-sans, sans-serif';

        this.#chart = new Chart($(this.element).find('.metal-price-chart')[0], {
            type: 'line',
            data: {
                labels: chartData.labels,
                datasets: [
                    {
                        data: chartData.data,
                        tension: 0,
                        fill: false,
                        borderWidth: 1,
                        borderColor: '#e0251b',
                        backgroundColor: 'white',
                        pointRadius: this.#getLinePointRadius(chartData.data)
                    }
                ],
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {display: false,},
                    title: {
                        display: true,
                        text: chartData.title,
                        fontSize: '14',
                        fontStyle: 'normal'
                    },
                    hover: {animationDuration: 0,},
                    scales: {
                        xAxes: [
                            {
                                ticks: {
                                    autoSkip: true,
                                    autoSkipPadding: 20,
                                    maxRotation: 0,
                                    minRotation: 0
                                }
                            }
                        ]
                    }
                }
            }
        });
    }

    #destroyChart() {
        if (this.#chart) {
            this.#chart.destroy();
            this.#chart = null;
        }
    }

    #getLinePointRadius(data) {
        if (data.length > 150) {
            return 0;
        }
        if (window.innerWidth <= 680) {
            return 0;
        }
        return 2;
    }
}
