import { Controller } from "@hotwired/stimulus";
import throttle from "lodash/throttle";

export default class extends Controller {
    static targets = ["toggleButton", "nav", "section"];

    connect() {
        this.#highlightNavigation = throttle(this.#highlightNavigation.bind(this), 200);
        window.addEventListener('scroll', this.#highlightNavigation);

        this.navTarget.querySelectorAll('a').forEach(link => {
            link.addEventListener('click', event => this.#scrollToSection(event));
        });
    }

    disconnect() {
        window.removeEventListener('scroll', this.#highlightNavigation);

        this.navTarget.querySelectorAll('a').forEach(link => {
            link.removeEventListener('click', event => this.#scrollToSection(event));
        });
    }

    toggleNav() {
        this.toggleButtonTarget.classList.toggle('is-open');
        this.navTarget.classList.toggle('is-open');
    }

    #private

    #scrollToSection(event) {
        event.preventDefault();
        const targetId = event.target.getAttribute('href').slice(1);
        const targetSection = document.getElementById(targetId);

        if (targetSection) {
            const navbarHeight = this.navTarget.offsetHeight + 20;
            const sectionTop = targetSection.getBoundingClientRect().top + window.pageYOffset - navbarHeight;

            window.scrollTo({
                top: sectionTop,
                behavior: 'instant'
            });
            this.#highlightNavigation();
        }
    }

    #highlightNavigation() {
        let scrollPosition = window.scrollY;
        let selectedLink = null;

        this.sectionTargets.forEach((section, index) => {
            if (scrollPosition >= section.offsetTop - (this.navTarget.offsetHeight + 20) - 1) {
                selectedLink = this.element.querySelector(`a[href='#${section.id}']`);
            }
        });

        if (selectedLink) {
            const currentActive = this.element.querySelector('.is-active');
            if (currentActive) {
                currentActive.classList.remove('is-active');
            }
            selectedLink.classList.add('is-active');
        }
    }
}
